import Container_ThreeDeeAreaSkinComponent from '@wix/thunderbolt-elements/src/components/Container/viewer/skinComps/ThreeDeeAreaSkin/ThreeDeeAreaSkin.skin';


const Container_ThreeDeeAreaSkin = {
  component: Container_ThreeDeeAreaSkinComponent
};


export const components = {
  ['Container_ThreeDeeAreaSkin']: Container_ThreeDeeAreaSkin
};

